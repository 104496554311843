import React, { Fragment } from "react"
import "./RoleDetail.css"
import JobDescription from "../../../../components/v0.1/0.1.1/JobDescription/JobDescription"
import Form from "./Form/Form"
import data from "../../../../constants/contents/jds.json"
import RoleDetailTab from "../../../../components/v0.1/0.1.1/RoleDetailTab/RoleDetailTab"

interface RoleDetailProps {
  roleDetail:
    | {
        id: number
        title: string
        role: string
        address: string
      }
    | undefined
  onApply: (status: boolean) => void
}

type TabsType = {
  index: number
}[]

// Tabs Array
const tabs: TabsType = [
  {
    index: 1,
  },
  {
    index: 2,
  },
]

const RoleDetail = (props: RoleDetailProps) => {
  const ref = React.useRef<null | HTMLDivElement>(null)
  const [selectedTab, setSelectedTab] = React.useState<number>(tabs[0].index)
  const [applyCondition, setApplyCondition] = React.useState(false)

  return (
    <div className='role-detail-page'>
      <div className='role-detail-page__header'>
        <div
          className='flex gap-1 hover:bg-primary hover:bg-opacity-20 duration-500 py-1 w-fit'
          onClick={() => props.onApply(false)}
        >
          <img src='./assets/back-arrow.svg' alt='' />
          <button className='w-fit text-primary'>Back</button>
        </div>
      </div>
      <div className='role-detail-page__container'>
        <div>
          {data.data.map((item, index) => {
            if (props.roleDetail?.id == item.id) {
              return (
                <div key={index} className='flex flex-col gap-8'>
                  <div className='container__position-title'>
                    <div className='flex flex-col gap-1 tablets:flex-row tablets:justify-between'>
                      <div className='flex flex-col gap-1'>
                        <h4 className='font-montserrat'>{props.roleDetail?.title}</h4>
                        <div className='flex gap-[8px] mt-[32px] flex-wrap'>
                          <p className='body-text-1--regular text-[14px] border-[1px] w-fit rounded-[20px] px-[12px] py-[6px]'>
                            {props.roleDetail?.role}
                          </p>
                          <p className='body-text-1--regular text-[14px] border-[1px] w-fit rounded-[20px] px-[12px] py-[6px]'>
                            {props.roleDetail?.address}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <RoleDetailTab
                    selectedTab={selectedTab}
                    applyCondition={applyCondition}
                    onClick={setSelectedTab}
                    tabs={[
                      {
                        label: "Job description",
                        index: 1,
                        Component: () => {
                          return (
                            <Fragment>
                              <div className='mb-[48px]'>
                                <span className='body-text-1--regular'>{item.description}</span>
                              </div>
                              {item.responsibilities && (
                                <JobDescription {...item.responsibilities} />
                              )}
                              <JobDescription {...item.requirement} />
                              {item.bonus && <JobDescription {...item.bonus} />}
                              <JobDescription {...item.benefits} />
                            </Fragment>
                          )
                        },
                      },
                      {
                        label: "Apply for this position",
                        index: 2,
                        Component: () => {
                          return (
                            <div ref={ref} className='small-desktops:max-w-[740px]'>
                              <Form
                                {...{
                                  position:
                                    props.roleDetail?.title +
                                    " - " +
                                    props.roleDetail?.role +
                                    " - " +
                                    props.roleDetail?.address,
                                  applyCondition: applyCondition,
                                  setApplyCondition(check) {
                                    setApplyCondition(check)
                                  },
                                }}
                              />
                            </div>
                          )
                        },
                      },
                    ]}
                  />
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default RoleDetail
